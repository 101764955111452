import Vue from 'vue'
import VueRouter from 'vue-router'

import * as guards from '@/router/guards'
import * as titleManager from '@/utils/title-manager'

import { TABS as HUB_TABS } from '@/components/Tenant/Hub/HubProfile/HubProfile.vue'
import { TABS as ADMIN_MEMBER_TABS } from '@/components/Admin/AdminPeople/Person/PersonTabs.vue'

import PublicTestPage from '@/pages/Public/PublicTestPage.vue'
import PublicHomePage from '@/pages/Public/PublicHomePage.vue'
import PublicAllTenantsPage from '@/pages/Public/PublicAllTenantsPage.vue'
import PublicNotFoundPage from '@/pages/Public/PublicNotFoundPage.vue'

import TenantPrincipalStatusInfo from '@/pages/Tenant/TenantPrincipalStatusInfo.vue'

const LayoutAuth = () => import('@/layouts/LayoutAuth.vue')
const LayoutDefault = () => import('@/layouts/LayoutDefault.vue')
const LayoutEmpty = () => import('@/layouts/LayoutEmpty.vue')
const LayoutPublic = () => import('@/layouts/LayoutPublic.vue')

const originalPush = VueRouter.prototype.push
const originalResolve = VueRouter.prototype.resolve

const defineAdminTitle = title => {
  const adminSymbol = '🛡' //🔒

  return [adminSymbol, title].join(' ')
}

VueRouter.prototype.push = function push(to) {
  return originalPush.call(this, to).catch(error => {
    if (error.name === 'NavigationDuplicated') {
      console.warn('Navigation duplicated')
    } else if (error.message?.includes('Redirected when going from')) {
      console.warn('Route path dynamic updated')
    } else {
      console.warn(error)
    }
  })
}

VueRouter.prototype.open = function (to) {
  const routeData = originalResolve.call(this, to)

  window.open(routeData.href, '_blank')
}

const routes = [
  {
    path: '/test',
    name: 'public.test',
    component: PublicTestPage,
    meta: { title: 'Test' }
  },

  {
    path: '/',
    name: 'public.home',
    beforeEnter: guards?.multiple([
      guards.onlyNotAuthorized,
      guards.onlyNoTenant
    ]),
    component: PublicHomePage,
    meta: { title: 'Companies' }
  },

  {
    path: '/test-registration',
    name: 'public.test-registration',
    beforeEnter: guards?.multiple([
      guards.onlyNotAuthorized,
      guards.onlyNoTenant
    ]),
    component: PublicAllTenantsPage,
    meta: { title: 'All Companies' }
  },

  {
    path: '/404',
    name: '404',
    beforeEnter: guards.onlyAuthorized,
    component: PublicNotFoundPage,
    meta: { title: 'Page Not Found' }
  },

  {
    path: '/select-tenant',
    beforeEnter: guards?.multiple([
      guards.onlyAuthorized,
      guards.onlyMultiTenantUser
    ]),
    component: LayoutDefault,
    children: [
      {
        path: '',
        name: 'select-tenant',
        component: () => import('@/pages/SelectTenant/SelectTenantPage.vue'),
        meta: { title: 'Select Company' }
      }
    ]
  },

  {
    path: '/admin',
    beforeEnter: guards?.multiple([
      guards.onlyAuthorized,
      guards.onlyAdmin,
      guards.principalStatusCheck
    ]),
    component: LayoutDefault,
    children: [
      {
        path: '',
        name: 'admin.home',
        component: () => import('@/pages/Admin/AdminDashboard.vue'),
        meta: { title: defineAdminTitle('Dashboard') }
      },
      {
        path: 'old-member-finder',
        component: LayoutEmpty,
        children: [
          {
            path: '',
            name: 'admin.old-member-finder',
            redirect: () => ({
              name: 'admin.old-member-finder.anonymize',
              params: { anonymize: 'no' }
            })
          },
          {
            path: ':anonymize(yes|no)',
            name: 'admin.old-member-finder.anonymize',
            component: () => import('@/pages/Admin/AdminOldMemberFinder.vue'),
            props: ({ params }) => ({ ...params }),
            meta: { title: defineAdminTitle('Member Finder') }
          }
        ]
      },
      {
        path: 'member-finder',
        component: LayoutEmpty,
        children: [
          {
            path: '',
            name: 'admin.member-finder',
            redirect: () => ({
              name: 'admin.member-finder.type',
              params: { type: 'public' }
            })
          },
          {
            path: ':type(public|private)',
            name: 'admin.member-finder.type',
            component: () => import('@/pages/Admin/AdminMemberFinder.vue'),
            props: ({ params }) => ({ ...params }),
            meta: { title: defineAdminTitle('New Member Finder') }
          }
        ]
      },
      {
        path: 'people',
        component: LayoutEmpty,
        children: [
          {
            path: '',
            name: 'admin.people',
            component: () =>
              import('@/pages/Admin/AdminPeople/AdminPeopleSearchPage.vue'),
            meta: { title: defineAdminTitle('People') }
          },
          {
            path: ':id/:tabCode?',
            name: 'admin.people.id',
            component: () =>
              import('@/pages/Admin/AdminPeople/AdminPeoplePersonPage.vue'),
            props: ({ params }) => ({ ...params }),
            meta: { title: defineAdminTitle('Person') }
          }
        ]
      },
      {
        path: 'hubs',
        component: LayoutEmpty,
        children: [
          {
            path: 'new',
            name: 'admin.new-hubs',
            component: () => import('@/pages/Admin/AdminHubs/AdminNewHubs.vue'),
            meta: { title: defineAdminTitle('Hubs') }
          },
          {
            path: '',
            name: 'admin.hubs',
            component: () =>
              import('@/pages/Admin/AdminHubs/AdminHubsList.vue'),
            meta: { title: defineAdminTitle('Old Hubs') }
          },
          {
            path: ':id/:tabCode?',
            name: 'admin.hubs.id',
            component: () =>
              import('@/pages/Admin/AdminHubs/AdminHubsItem.vue'),
            props: ({ params }) => ({ ...params }),
            meta: { title: defineAdminTitle('Hub') }
          }
        ]
      },

      {
        path: 'teams/:id?',
        name: 'admin.teams',
        component: () => import('@/pages/Admin/AdminTeams/AdminTeams.vue'),
        props: ({ params }) => ({ ...params }),
        meta: { title: defineAdminTitle('Teams') }
      },

      {
        path: 'new-teams',
        component: LayoutEmpty,
        children: [
          {
            path: '',
            name: 'admin.new-teams',
            component: () =>
              import('@/pages/Admin/AdminTeams/AdminTeamsCatalog.vue'),
            meta: { title: defineAdminTitle('New Teams') }
          },
          {
            path: ':id',
            name: 'admin.new-teams.id',
            component: () =>
              import('@/pages/Admin/AdminTeams/AdminTeamsProfile.vue'),
            props: ({ params }) => ({ ...params }),
            meta: { title: defineAdminTitle('New Team') }
          }
        ]
      },

      {
        path: 'faqs',
        name: 'admin.faqs',
        component: () => import('@/pages/Admin/AdminFaqs.vue'),
        meta: { title: defineAdminTitle('FAQs') }
      },

      {
        path: 'announcements',
        name: 'admin.announcements',
        component: () => import('@/pages/Admin/AdminAnnouncements.vue'),
        meta: { title: defineAdminTitle('Announcements') }
      },

      {
        path: 'dynamic-forms-manager',
        name: 'admin.dynamic-forms-manager',
        component: () => import('@/pages/Admin/AdminDynamicFormsManager.vue'),
        meta: { title: defineAdminTitle('Forms') }
      },
      {
        path: 'contracts',
        name: 'admin.contracts-manager',
        component: () => import('@/pages/Admin/AdminContractsManager.vue'),
        meta: { title: defineAdminTitle('Contracts') }
      },

      {
        path: 'assessment',
        component: LayoutEmpty,
        children: [
          {
            path: 'definitions',
            component: LayoutEmpty,
            children: [
              {
                path: '',
                name: 'admin.assessment.definitions',
                component: () =>
                  import(
                    '@/pages/Admin/AdminAssessment/AdminAssessmentDefinitions.vue'
                  ),
                meta: { title: defineAdminTitle('Assessments') }
              },
              {
                path: ':definitionId/tasks',
                component: LayoutEmpty,
                children: [
                  {
                    path: '',
                    name: 'admin.assessment.definitions.id.tasks',
                    component: () =>
                      import(
                        '@/pages/Admin/AdminAssessment/AdminAssessmentDefinitionTasks.vue'
                      ),
                    props: ({ params }) => ({ ...params }),
                    meta: { title: defineAdminTitle('Assessment Attempts') }
                  },
                  {
                    path: ':taskId/attempts/:attemptId',
                    name: 'admin.assessment.definitions.id.tasks.id.attempts.id',
                    component: () =>
                      import(
                        '@/pages/Admin/AdminAssessment/AdminAssessmentDefinitionTaskAttemptReview.vue'
                      ),
                    props: ({ params }) => ({
                      definitionId: Number(params.definitionId),
                      taskId: Number(params.taskId),
                      attemptId: Number(params.attemptId)
                    }),
                    meta: { title: defineAdminTitle('Assessment Attempt') }
                  }
                ]
              }
            ]
          }
        ]
      },

      {
        path: 'pages',
        component: LayoutEmpty,
        children: [
          {
            path: '',
            name: 'admin.pages',
            component: () =>
              import('@/pages/Admin/AdminPages/AdminPagesList.vue'),
            meta: { title: defineAdminTitle('Widgets') }
          },
          {
            path: ':pageId',
            component: LayoutEmpty,
            children: [
              {
                path: '',
                name: 'admin.pages.id',
                component: () =>
                  import('@/pages/Admin/AdminPages/AdminPagesItem.vue'),
                props: ({ params }) => ({ ...params }),
                meta: { title: defineAdminTitle('Widget') }
              },
              {
                path: 'contents/:contentId',
                name: 'admin.pages.id.contents.id',
                component: () =>
                  import(
                    '@/pages/Admin/AdminPages/AdminPagesItemContentItem.vue'
                  ),
                props: ({ params }) => ({ ...params }),
                meta: { title: defineAdminTitle('Widget Content') }
              }
            ]
          }
        ]
      },

      {
        path: 'categories',
        name: 'admin.categories',
        component: () => import('@/pages/Admin/AdminCategories.vue'),
        meta: { title: defineAdminTitle('Widget Categories') }
      },

      {
        path: 'featured-creatives',
        component: LayoutEmpty,
        children: [
          {
            path: '',
            name: 'admin.featured-creatives',
            component: () =>
              import(
                '@/pages/Admin/AdminFeaturedCreatives/AdminFeaturedCreatives.vue'
              ),
            meta: { title: defineAdminTitle('Featured Creatives') }
          },
          {
            path: ':id',
            name: 'admin.featured-creatives.id',
            component: () =>
              import(
                '@/pages/Admin/AdminFeaturedCreatives/AdminFeaturedCreativesItem.vue'
              ),
            props: ({ params }) => ({ ...params }),
            meta: { title: defineAdminTitle('Featured Creative') }
          },
          {
            path: ':id/preview',
            name: 'admin.featured-creatives.id.preview',
            component: () =>
              import(
                '@/pages/Admin/AdminFeaturedCreatives/AdminFeaturedCreativesItemPreview.vue'
              ),
            props: ({ params }) => ({ ...params }),
            meta: { title: defineAdminTitle('Featured Creative Preview') }
          }
        ]
      },

      {
        path: 'showcases',
        name: 'admin.showcases',
        component: () => import('@/pages/Admin/AdminShowcases.vue'),
        meta: { title: defineAdminTitle('Showcases') }
      },

      {
        path: 'job-manager',
        component: () =>
          import('@/pages/Admin/AdminJobManager/AdminJobManager.vue'),
        children: [
          {
            path: '',
            name: 'admin.job-manager',
            component: () =>
              import('@/pages/Admin/AdminJobManager/AdminJobManagerList.vue'),
            meta: { title: defineAdminTitle('Job List') }
          },
          {
            path: ':id',
            name: 'admin.job-manager.id',
            component: () =>
              import('@/pages/Admin/AdminJobManager/AdminJobManagerItem.vue'),
            props: ({ params }) => ({ ...params }),
            meta: { title: defineAdminTitle('Job Detail') }
          }
        ]
      },

      {
        path: 'submission-reviewer',
        component: LayoutEmpty,
        children: [
          {
            path: '',
            name: 'admin.submission-reviewer',
            component: () =>
              import(
                '@/pages/Admin/AdminSubmissionReviewer/AdminSubmissionReviewerSearch.vue'
              ),
            meta: { title: defineAdminTitle('Submission Reviewer List') }
          },
          {
            path: ':id',
            name: 'admin.submission-reviewer.id',
            component: () =>
              import(
                '@/pages/Admin/AdminSubmissionReviewer/AdminSubmissionReviewerItem.vue'
              ),
            props: ({ params }) => ({ ...params }),
            meta: { title: defineAdminTitle('Submission Reviewer Item') }
          }
        ]
      },

      {
        path: 'accounting',
        name: 'admin.accounting',
        component: () => import('@/pages/Admin/AdminAccounting.vue'),
        meta: { title: defineAdminTitle('Accounting') }
      },

      {
        path: 'attributes',
        name: 'admin.attributes',
        component: () => import('@/pages/Admin/AdminAttributes.vue'),
        meta: { title: defineAdminTitle('Attributes') }
      },

      {
        path: 'professions',
        name: 'admin.professions',
        component: () => import('@/pages/Admin/AdminProfessions.vue'),
        meta: { title: defineAdminTitle('Professions') }
      },

      {
        path: 'medical-specialties',
        name: 'admin.medical-specialties',
        component: () => import('@/pages/Admin/AdminMedicalSpecialties.vue'),
        meta: { title: defineAdminTitle('Medical Specialties') }
      },

      {
        path: 'saved-searches',
        name: 'admin.saved-searches',
        component: () => import('@/pages/Admin/AdminSavedSearches.vue'),
        meta: { title: defineAdminTitle('New Saved Searches') }
      },

      {
        path: 'old-saved-searches',
        name: 'admin.old-saved-searches',
        component: () => import('@/pages/Admin/AdminOldSavedSearches.vue'),
        meta: { title: defineAdminTitle('Saved Searches') }
      },

      {
        path: 'tenants',
        name: 'admin.tenants',
        beforeEnter: guards.onlyMultiTenantUser,
        component: () => import('@/pages/Admin/AdminTenantsPage.vue'),
        meta: { title: defineAdminTitle('Tenants') }
      },

      {
        path: 'job-submissions/:submitterId/:assignmentId',
        name: 'admin.job-submissions-viewer',
        props: ({ params }) => ({
          submitterId: Number(params.submitterId),
          assignmentId: Number(params.assignmentId)
        }),
        component: () => import('@/pages/Admin/AdminJobSubmissions.vue'),
        meta: { title: defineAdminTitle('Job Submissions Viewer') }
      }
    ]
  },

  {
    path: '/:tenantMarker',
    component: LayoutEmpty,
    children: [
      {
        path: '',
        beforeEnter: guards?.multiple([
          guards.onlyAuthorized,
          guards.verifyPrincipalTenant
        ]),
        component: LayoutDefault,
        props: { isAnnouncements: true },
        children: [
          {
            path: '',
            beforeEnter: guards?.multiple([
              guards.principalStatusCheck,
              guards.onlyAccountComplete
            ]),
            component: LayoutEmpty,
            children: [
              {
                path: '',
                name: 't.home',
                component: () => import('@/pages/Tenant/TenantHome.vue'),
                meta: { title: 'Home' }
              },
              {
                path: 'showcase',
                name: 't.showcase',
                // MYZIURA 2
                // this.$isTenant.crowdPharm
                beforeEnter: (...args) =>
                  guards.onlyTenants(...args, ['crowdpharm']),
                component: () => import('@/pages/Tenant/TenantShowcase.vue'),
                meta: { title: 'Showcase' }
              },
              {
                path: 'faqs',
                name: 't.faqs',
                component: () => import('@/pages/Tenant/TenantFaqs.vue'),
                meta: { title: 'FAQs' }
              },
              {
                path: 'tech-support',
                name: 't.tech-support',
                component: () => import('@/pages/Tenant/TenantTechSupport.vue'),
                meta: { title: 'Tech Support' }
              },
              {
                path: 'talk',
                name: 't.talk',
                component: () => import('@/pages/Tenant/TenantTalk.vue'),
                meta: { title: 'Talk To Us' }
              },
              {
                path: 'content/:id',
                name: 't.content.id',
                component: () => import('@/pages/Tenant/TenantContent.vue'),
                props: ({ params }) => ({ ...params }),
                meta: { title: 'Content' }
              },
              {
                path: 'featured-creatives',
                component: LayoutEmpty,
                children: [
                  {
                    path: '',
                    name: 't.featured-creatives',
                    component: () =>
                      import(
                        '@/pages/Tenant/FeaturedCreatives/FeaturedCreatives.vue'
                      ),
                    meta: { title: 'Featured Creatives' }
                  }
                ]
              },
              {
                path: 'member',
                beforeEnter: guards.onlyMember,
                component: LayoutEmpty,
                children: [
                  {
                    path: 'profile',
                    component: LayoutEmpty,
                    children: [
                      {
                        path: '',
                        name: 't.member.profile',
                        redirect: ({ params }) => ({
                          name: 't.member.profile.tab',
                          params
                        })
                      },
                      {
                        path: ':tabCode',
                        name: 't.member.profile.tab',
                        component: () =>
                          import('@/pages/Tenant/Member/MemberProfile.vue'),
                        props: ({ params }) => ({ ...params }),
                        meta: { title: 'Member Profile' }
                      }
                    ]
                  },
                  {
                    path: 'projects',
                    component: LayoutEmpty,
                    children: [
                      {
                        path: '',
                        name: 't.member.projects',
                        component: () =>
                          import(
                            '@/pages/Tenant/Member/MemberProjects/MemberProjects.vue'
                          ),
                        meta: { title: 'Member Projects' }
                      },
                      {
                        path: ':assignmentId',
                        name: 't.member.projects.id',
                        component: () =>
                          import(
                            '@/pages/Tenant/Member/MemberProjects/MemberProjectsItem.vue'
                          ),
                        props: ({ params }) => ({ ...params }),
                        meta: { title: 'Member Project' }
                      },
                      {
                        path: 'invitation/:inviteeId',
                        name: 't.member.projects.invitation.id',
                        component: () =>
                          import(
                            '@/pages/Tenant/Member/MemberProjects/MemberProjectsInvitation.vue'
                          ),
                        props: ({ params }) => ({ ...params }),
                        meta: { title: 'Member Project Invitation' }
                      }
                    ]
                  }
                ]
              },
              {
                path: 'hub',
                beforeEnter: guards.onlyHub,
                component: LayoutEmpty,
                children: [
                  {
                    path: 'profile',
                    component: LayoutEmpty,
                    children: [
                      {
                        path: '',
                        name: 't.hub.profile',
                        redirect: ({ params }) => ({
                          name: 't.hub.profile.tab',
                          params
                        })
                      },
                      {
                        path: ':tabCode',
                        name: 't.hub.profile.tab',
                        component: () =>
                          import('@/pages/Tenant/Hub/HubProfile.vue'),
                        props: ({ params }) => ({ ...params }),
                        meta: { title: 'Company Profile' }
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            path: '',
            beforeEnter: guards?.multiple([
              guards.principalStatusCheck,
              guards.onlyAccountIncomplete
            ]),
            component: LayoutEmpty,
            children: [
              {
                path: 'sign-required-contracts',
                name: 't.sign-required-contracts',
                beforeEnter: guards.onlyNotAllRequiredContractsSigned,
                component: () =>
                  import('@/pages/Tenant/TenantSignRequiredContracts.vue'),
                props: ({ params }) => ({ ...params }),
                meta: { title: 'Sign Required Contracts' }
              },

              {
                path: 'hub',
                beforeEnter: guards?.multiple([
                  guards.onlyHub,
                  guards.onlyNotCompletedProfile
                ]),
                component: LayoutEmpty,
                children: [
                  {
                    path: 'complete-profile',
                    name: 't.hub.complete-profile',
                    component: () =>
                      import('@/pages/Tenant/Hub/HubCompleteProfile.vue')
                  }
                ]
              },

              {
                path: 'principal-status-info',
                name: 't.principal-status-info',
                component: TenantPrincipalStatusInfo,
                meta: { title: 'Status Info' }
              },
              {
                path: 'member',
                beforeEnter: guards.onlyMember,
                component: LayoutEmpty,
                children: [
                  {
                    path: 'complete-registration',
                    name: 't.member.complete-registration',
                    component: () =>
                      import(
                        '@/pages/Tenant/Member/MemberCompleteRegistration.vue'
                      ),
                    meta: { title: 'Member Complete Registration' }
                  }
                ]
              },

              {
                path: 'hub',
                beforeEnter: guards.onlyHub,
                component: LayoutEmpty,
                children: [
                  {
                    path: 'complete-registration',
                    name: 't.hub.complete-registration',
                    component: () =>
                      import('@/pages/Tenant/Hub/HubCompleteRegistration.vue'),
                    meta: { title: 'Company Complete Registration' }
                  }
                ]
              }
            ]
          }
        ]
      },

      {
        path: '',
        beforeEnter: guards?.multiple([
          guards.verifyGlobalTenant,
          guards.setTenantTheme
        ]),
        component: LayoutAuth,
        children: [
          {
            path: 'auth',
            beforeEnter: guards.onlyNotAuthorized,
            component: LayoutEmpty,
            children: [
              {
                path: 'login',
                name: 't.auth.login',
                component: () => import('@/pages/Tenant/Auth/AuthLogin.vue'),
                props: ({ params }) => ({ ...params }),
                meta: { title: 'Login' }
              },
              {
                path: 'forgot-password',
                name: 't.auth.forgot-password',
                component: () =>
                  import('@/pages/Tenant/Auth/AuthForgotPassword.vue'),
                props: ({ params }) => ({ ...params }),
                meta: { title: 'Forgot Password' }
              },
              {
                path: 'reset-password/:userId/:email/:token',
                name: 't.auth.reset-password',
                component: () =>
                  import('@/pages/Tenant/Auth/AuthResetPassword.vue'),
                props: ({ params }) => ({ ...params }),
                meta: { title: 'Reset Password' }
              },

              {
                path: 'register',
                component: LayoutEmpty,
                children: [
                  {
                    path: '',
                    name: 't.auth.register',
                    component: () =>
                      import(
                        '@/pages/Tenant/Auth/AuthRegistrationSelectUserTypeForm.vue'
                      ),
                    props: ({ params }) => ({ ...params }),
                    meta: { title: 'Sign Up' }
                  },
                  {
                    path: ':userTypeName',
                    name: 't.auth.register.user-type-name',
                    component: () =>
                      import('@/pages/Tenant/Auth/AuthRegistration.vue'),
                    props: ({ params }) => ({ ...params }),
                    meta: { title: 'Sign Up' }
                  }
                ]
              },

              {
                path: 'test-register',
                component: LayoutEmpty,
                children: [
                  {
                    path: ':userTypeName',
                    name: 't.auth.test-register.user-type-name',
                    component: () =>
                      import('@/pages/Tenant/Auth/AuthRegistration.vue'),
                    props: ({ params }) => ({ ...params }),
                    meta: { title: 'Test Sign Up' }
                  }
                ]
              }
            ]
          },

          {
            path: 'mfa',
            beforeEnter: guards.onlyIsPreAuthorizedOrAuthorized,
            component: LayoutEmpty,
            children: [
              {
                path: 'config',
                name: 't.mfa.config',
                beforeEnter: guards.mfaConfigPage,
                component: () =>
                  import(
                    '@/pages/Tenant/MultiFactorAuth/MultiFactorAuthConfigPage.vue'
                  ),
                props: ({ params }) => ({ ...params }),
                meta: { title: 'Multi-Factor Authentication' }
              },
              {
                path: 'verification/:optionId/:phoneNumber',
                name: 't.mfa.verification',
                component: () =>
                  import(
                    '@/pages/Tenant/MultiFactorAuth/MultiFactorAuthVerificationPage.vue'
                  ),
                props: ({ params }) => ({ ...params }),
                meta: { title: 'Multi-Factor Authentication Verification' }
              }
            ]
          }
        ]
      },

      {
        path: '',
        beforeEnter: guards?.multiple([
          guards.verifyGlobalTenant,
          guards.setTenantTheme
        ]),
        component: LayoutPublic,
        children: [
          {
            path: 'privacy',
            name: 't.privacy',
            component: () => import('@/pages/Public/PublicPrivacy.vue'),
            meta: { title: 'Privacy' }
          },

          {
            path: 'featured-creatives/:id',
            name: 't.featured-creatives.id',
            component: () =>
              import(
                '@/pages/Tenant/FeaturedCreatives/FeaturedCreativesItem.vue'
              ),
            props: ({ params }) => ({ ...params }),
            meta: { title: 'Featured Creative' }
          },

          {
            path: 'ai-guidelines',
            name: 't.ai-guidelines',
            component: () => import('@/pages/Public/PublicAiGuidelines.vue'),
            props: ({ params }) => ({ ...params }),
            meta: { title: 'AI Guidelines' }
          }
        ]
      }
    ]
  },

  {
    path: '*',
    redirect: { name: '404' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ x: 0, y: 0 })
})

const defineIsFirstPartsEqual = (str1 = '', str2 = '') => {
  const length = str1.replace(/\s/g, '').length

  const firstPart1 = str1.replace(/\s/g, '').slice(0, length)
  const firstPart2 = str2.replace(/\s/g, '').slice(0, length)

  return firstPart1 === firstPart2
}

router.afterEach(to => {
  let [tenantName, pageName] = titleManager.getTitle()
  const isFirstPartsEqual = defineIsFirstPartsEqual(to.meta?.title, pageName)
  pageName = isFirstPartsEqual ? pageName : to.meta?.title

  const arrayTitle = [tenantName, pageName]

  if (
    to.name === 't.member.profile.tab' ||
    (to.name === 'admin.people.id' && to.params.tabCode)
  ) {
    const tabTitle = ADMIN_MEMBER_TABS.labelByCode[to.params.tabCode]
    arrayTitle.push(tabTitle)
  } else if (
    to.name === 't.hub.profile.tab' ||
    (to.name === 'admin.hubs.id' && to.params.tabCode)
  ) {
    const tabTitle = HUB_TABS.labelByCode[to.params.tabCode]
    arrayTitle.push(tabTitle)
  }

  titleManager.setTitle(arrayTitle)
})

Vue.use(VueRouter)

export default router
